<template>
  <div class="base-item-com-page">
    <div class="base-title">
      <div class="mr-40">{{ titleText }}</div>
      <slot name="itemBtn"></slot>
    </div>
    <div class="base-line"></div>
    <slot name="itemInfo"></slot>
  </div>
</template>
<script>
export default {
  props: ['titleText'],
  data() {
    return {}
  }
}
</script>
<style scoped lang="scss">
.base-item-com-page {
  width: 100%;
  margin-bottom: 40px;
  .base-line {
    margin-top: 10px;
    width: 40%;
    height: 1px;
    background: #E2E2E2;
  }
  .base-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #323233;
  }
}
</style>