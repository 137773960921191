import {objArrMapObj} from "@/untils/otherEvent"

/** 订单类型 */
export const orderTypeList = [
    { name: '一口价', value: 1 },
    { name: '竞拍单', value: 0 },
]

export const orderTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', orderTypeList)[value]
}

/** 订单类型对应的类 */
export const orderTypeClassType = {
    0: 'color-orange',
    1: 'color-green'
}

/** 支付方式 */
export const payTypeList = [
    { name: '小程序', value: 1 },
    { name: 'APP', value: 2 },
]

export const payTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', payTypeList)[value]
}

/** 订单类型对应的类 */
export const payTypeClassType = {
    1: 'color-green',
    2: 'color-blue'
}

/** 商品等级 */
export const orderProductLevel = [
    { name: "臻品", value: 8 },
    { name: "精品", value: 1 },
    { name: "普品", value: 2 },
    { name: "差品", value: 3 },
]

export const orderProductLevelMapText = (value) => {
    return objArrMapObj('value', 'name', orderProductLevel)[value]
}

/** 商品等级类型对应的类 */
export const orderProductLevelClassType = {
    8: 'color-orange',
    1: 'color-blue',
    2: 'color-black',
    3: 'color-red',
}

/** 评级类型 */
export const rateServiceTypeStatusList = [
    { name: '一口价', value: -1 },
    { name: '盒子币', value: 0 },
    { name: 'A类-选择评级', value: 1 },
    { name: 'A类-选择评级选择标版', value: 11 },
    { name: 'A类-放弃评级', value: 2 },
    { name: 'B类-选择评级', value: 3 },
    { name: 'B类-选择评级-标版', value: 13 },
    { name: 'B类-不评级', value: 4 },
    { name: 'C类-延长送评', value: 5 },
    { name: 'C类-延长送评-标版', value: 15 },
]

export const rateServiceTypeStatusListMapText = (value) => {
    return objArrMapObj('value', 'name', rateServiceTypeStatusList)[value]
}

/** 评级类型对应的类 */
export const rateServiceTypeStatusClassType = {
    '-1': 'color-blue',
    0: 'color-blue',
    1: 'color-green',
    11: 'color-green-1',
    2: 'color-red',
    3: 'color-blue',
    4: 'color-1-blue',
    5: 'color-blue-1',
    15: 'color-1-blue'
}

/** 正向订单状态 */
export const orderStatusTypeList = [
    { name: "非取消", value: -2 },
    { name: "已取消", value: -1 },
    { name: "待付款", value: 0 },
    { name: "待发货", value: 1 },
    { name: "待收货", value: 3 },
    { name: "延长收货", value: 4 },
    { name: "待评价", value: 5 },
    { name: "已完成", value: 7 },
]
export const orderStatusTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', orderStatusTypeList)[value]
}

/** 商品等级类型对应的类 */
export const orderStatusTypeListClassType = {
    '-2': 'color-black',
    '-1': 'color-gray',
    0: 'color-red',
    1: 'color-blue',
    3: 'color-black',
    4: 'color-black',
    5: 'color-black',
    7: 'color-gray',
}

/** 正向订单退款状态 */
export const orderReturnPriceStatus = [
    { name: "退款中", value: -10 },
    { name: "退款取消", value: -12 },
    { name: "退款完成", value: -14 },
]

export const orderReturnPriceStatusMapText = (value) => {
    return objArrMapObj('value', 'name', orderReturnPriceStatus)[value]
}

/** 商品等级类型对应的类 */
export const orderReturnPriceStatusClassType = {
    '-10': 'color-red',
    '-2': 'color-gray',
    '-14': 'color-black',
}

